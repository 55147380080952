<template>
  <div class="smallLoans">
    <header class="header-bg xd-slide">
      <section class="xd-tabs">
        <span class="header-img"></span>
        <div class="xd-tabs-container">
          <img src="../assets/images/logo.png" alt="" />
          <div class="space-width"></div>
          <div class="space-fight">
            <template v-for="(item, index) in tabList">
              <a class="xd-tab" :key="item.id" @click="scrollTo(index)">
                {{ item.tabName }}
              </a>
            </template>
          </div>
        </div>
        <img src="../assets/images/clerk.png" alt="" class="xd-tabs-clerk" />
      </section>
      <section
        class="xd-tabs-fixed"
        :class="{ show: tabIndex !== 0 }"
        v-if="tabIndex !== 0"
      >
        <div class="xd-tabs-container">
          <img src="../assets/images/logo.png" alt="" />
          <div class="space-width"></div>
          <div class="space-right">
            <template v-for="(item, index) in tabList">
              <a
                class="xd-tab"
                :class="{
                  tabColor: tabIndex === index,
                  tabClick: tabIndex === index,
                }"
                :key="item.id"
                @click="scrollTo(index)"
              >
                {{ item.tabName }}
              </a>
            </template>
          </div>
        </div>
      </section>
    </header>
    <main class="xd-main">
      <section class="xd-slide xd-company">
        <div class="xd-company-title">公司介绍</div>
        <div class="xd-company-content">
          <div class="xd-company-content-text">
            国美小额贷款有限公司是天津市审批并挂牌的小额贷款公司，注册资本5亿元+人民币。公司拥有一批多年从事综合金融、信贷的专业人才，公司坚持“诚信为本”、“合法经营”、“依法纳税”的原则，充分发挥小贷公司“周期短”、“效率高”、“放款快”的优势，为客户群提供场景化、个性化的专属金融服务。
          </div>
        </div>
        <div class="xd-company-introduce-container">
          <div class="xd-company-introduce">
            <img
              class="xd-company-introduce-left"
              src="../assets/images/socialCreditCode.png"
              alt=""
            />
            <div class="xd-company-introduce-right">
              <div class="xd-company-introduce-right-title">社会信用代码</div>
              <div class="xd-company-introduce-right-content">
                9112011634088456X4
              </div>
            </div>
          </div>
          <div class="xd-company-introduce xd-company-introduce-middle">
            <img
              class="xd-company-introduce-left"
              src="../assets/images/legalRepresentative.png"
              alt=""
            />
            <div class="xd-company-introduce-right">
              <div class="xd-company-introduce-right-title">法定代表人</div>
              <div class="xd-company-introduce-right-content">陈萍</div>
            </div>
          </div>
          <div class="xd-company-introduce">
            <img
              class="xd-company-introduce-left"
              src="../assets/images/administrativeOfficer.png"
              alt=""
            />
            <div class="xd-company-introduce-right">
              <div class="xd-company-introduce-right-title">高级管理人员</div>
              <div class="xd-company-introduce-right-content">曹丽亮</div>
            </div>
          </div>
        </div>
      </section>
      <section class="xd-slide xd-product">
        <div class="xd-product-title">产品介绍</div>
        <div class="xd-product-content">
          <div class="xd-product-content-left">
            <div class="xd-pro-cash key-cash"></div>
            <div class="xd-product-content-left-product">
              <div class="xd-product-content-left-product-title">
                取现分期<span>灵活融资，助力梦想起航</span>
              </div>
              <div class="xd-product-content-left-product-list">
                <div
                  class="xd-product-content-left-product-item"
                  v-for="(product, index) in productList"
                  :key="index"
                >
                  <div class="xd-product-content-left-product-list-title">
                    {{ product.title }}
                  </div>
                  <div
                    v-if="Array.isArray(product.content)"
                    class="xd-product-content-left-product-list-content-arr"
                  >
                    <div
                      class="xd-product-content-left-product-list-content-arr-item"
                      v-for="(item, index) in product.content"
                      :key="index"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <div
                    v-else
                    class="xd-product-content-left-product-list-content"
                  >
                    {{ product.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="xd-product-content-right">
            <div class="xd-pro-consume key-cash"></div>
            <div class="xd-product-content-right-product">
              <div class="xd-product-content-right-product-title">小易贷</div>
              <div class="xd-product-content-right-product-content">
                国美小贷上线“小易贷”，以实际行动促进小微企业可持续发展。通过APP渠道申请，面向全国以个体工商户为主（含农业生产经营个体工商户）的小微企业主，提供纯线上、无抵押、可循环使用的低息资金周转服务，助力小微企业缓解资金压力。
              </div>
              <div class="xd-product-content-right-product-content">
                额度最高可提升至300,000.00
              </div>
              <div class="xd-product-content-right-product-content">
                最长借款期限：12期
              </div>
              <div class="xd-product-content-right-product-content">
                贷款年化利率（单利）：7.2%-24%
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="xd-slide xd-gomeyk">
        <div class="xd-gomeyk-content">
          <div class="xd-gome-title">美易借钱</div>
          <div class="xd-gome-con">
            <span
              >美易借钱根据消费金融使用的场景不同，将消费金融分为线上消费金融与线下消费金融。产品涵盖消费分期、取现消费等。用户在线上进行消费结算时，金融业务的场景式嵌入，打破时间与空间的限制，满足消费者便捷需求的同时，也给予消费金融更多的发展角度和流量导入。而立足场景布局的线下分期，从真实的零售消费场景切入，满足消费者体验性购物的需求。</span
            >
          </div>
          <div class="xd-gome-wx">
            <img src="../assets/images/wx.png" alt="" />
            <span>扫码下载美易借钱APP</span>
          </div>
          <div class="xd-gome-card" :class="{ 'key-card': easyCardFlag }"></div>
        </div>
      </section>
      <section class="xd-slide xd-about">
        <div class="xd-about-title">关于我们</div>
        <div class="xd-about-content">
          <div class="xd-ab">
            国美小额贷款有限公司成立于2015年，注册资本3亿元，2019年增资至5亿元，公司地址位于天津市，是天津市首批互联网小额贷款公司试点单位，2017年接入中国人民银行征信系统。
          </div>
          <div class="xd-risk">
            <div class="xd-risk-content">
              <img src="../assets/images/scene.png" alt="" />
              <span class="xd-risk-scene">多场景覆盖</span>
              <span class="xd-risk-con"
                >以创新科技手段带动普惠金融场景延展，通过多元化家庭金融服务体系，为客户提供多渠道、一体化的产品和服务。</span
              >
            </div>
            <div class="xd-risk-content">
              <img src="../assets/images/risk.png" alt="" />
              <span class="xd-risk-scene">大数据智能风控</span>
              <span class="xd-risk-con"
                >以大数据驱动的运营营销体系“罗盘(TMH)”和风控体系“水滴(NCM)”为核心，多方位监控，精细化运营，以大数据驱动新金融，打造真正新金融。</span
              >
            </div>
            <div class="xd-risk-content">
              <img src="../assets/images/grc.png" alt="" />
              <span class="xd-risk-scene">合规安全</span>
              <span class="xd-risk-con"
                >严格遵守国家监管部门要求，无乱收费现象，保障客户的基本权益。用户信息全程加密存储，杜绝信息泄露，保障用户隐私安全。</span
              >
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer>
      <section>
        <div class="f-info">
          <div class="f-info-company">
            <span>联系方式</span>
            <span> 公司名称：国美小额贷款有限公司 </span>
            <span>
              地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：天津经济技术开发区第一大街79号泰达
            </span>
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MSD-C区C1座2802-1室
            </span>
          </div>
          <div class="f-info-contactInformation">
            <span>网&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;站：www.gomeeloan.com</span>
            <span>客服电话：400-8133-233(9:00-18:00)</span>
            <span>客服邮箱：finance-service@gomefinance.com.cn</span>
          </div>
          <div class="xd-gome-wx">
            <img src="../assets/images/wx.png" alt="" />
            <span>扫码下载美易借钱APP</span>
          </div>
        </div>
        <div class="f-record">
          <a target="_blank" href="https://beian.miit.gov.cn/"
            >津ICP备16003063号-7</a
          >
          <div>
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502035991"
              ><img src="../assets/images/police.png" /><span
                >京公网安备 11010502048755号</span
              ></a
            >
          </div>
        </div>
      </section>
    </footer>
  </div>
</template>
<script>
export default {
  name: "smallLoans",
  data() {
    return {
      productList: [
        {
          title: "适用人群",
          content:
            "借款用户需满足22岁以上，有稳定收入，资信良好，有明确的消费用途等条件。",
        },
        {
          title: "产品亮点",
          content:
            "无需抵押，仅凭个人信用即可申请，线上自动审核，最快3分钟放款。",
        },
        {
          title: "额度范围",
          content:
            "1,000元至200,000元不等，循环使用，具体额度根据个人信用评估结果确定。",
        },
        {
          title: "申请流程",
          content: [
            "app注册，下载app, 填写基本信息，做实名认证。",
            "额度激活：申请额度激活，根据提交的资料进行信用评估，确定贷款额度。",
            "提现申请：签订合同，合同生效后，资金将迅速打入指定账户。",
          ],
        },
        {
          title: "收费项目及标准",
          content: "利息、担保费 。综合年化利率（单利）7.2%-24%",
        },
        { title: "计息和还本付息方式", content: "等本等费 " },
        {
          title: "逾期贷款处理方式",
          content:
            "逾期后会联系您本人提醒您还款（包括但不限于以电话、短信、上门、诉讼等合法方式），当您本人无法联系时，可能会联系您预留的紧急联系人。 ",
        },
        {
          title: "风险提示",
          content:
            "借款人未按合同约定提供真实完整信息、使用贷款、偿还贷款等行为将被追究违约责任。逾期后会产生逾期罚息，且逾期记录会上报征信。此外，部分产品及期数的提前结清可能会产生提前还款手续费。请在经济状况许可的范围内选择适合自己的产品或服务，理性消费，谨慎借贷。",
        },
      ],
      tabList: [
        { id: 1, tabName: "首页", href: "tab-home" },
        { id: 2, tabName: "公司介绍", href: "tab-company" },
        { id: 3, tabName: "产品介绍", href: "tab-product" },
        { id: 4, tabName: "美易借钱", href: "tab-gomeyk" },
        { id: 5, tabName: "关于我们", href: "tab-about" },
      ],
      tabIndex: 0,
      sliderWidth: 0,
      sliderLeft: 0,
      easyCardFlag: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scorllmonitor);
  },
  methods: {
    // 锚点点击
    scrollTo(index) {
      this.tabIndex = index;
      console.log(this.tabIndex + "页面index");
      let slideTop =
        document.querySelectorAll(".xd-slide")[index].offsetTop - 84;
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      const STEP = 15;
      // 判断是往下滑还是往上滑
      if (scrollTop > slideTop) {
        smoothUp();
      } else {
        smoothDown();
      }
      // 下滑函数
      function smoothDown() {
        // scrollTop小于slideTop 视口未滑到指定位置
        if (scrollTop < slideTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了
          if (slideTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = slideTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothDown);
        }
      }
      // 上滑函数
      function smoothUp() {
        if (scrollTop > slideTop) {
          if (scrollTop - slideTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = slideTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
      this.setSlider();
      this.easyCard();
    },
    //易卡闪光部分
    easyCard() {
      if (this.tabIndex === 2) {
        this.easyCardFlag = true;
        setTimeout(() => {
          this.easyCardFlag = false;
        }, 1000);
      }
    },
    // 锚点监听
    scorllmonitor() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      // 获取锚点元素的scrollTop
      // const slideTop = document.querySelectorAll(".xd-slide");
      const offsetTopArr = [];
      // console.log(slideTop, "99");
      // slideTop.forEach((item) => {
      //   offsetTopArr.push(item.offsetTop - 90);
      // });
      //兼容ie
      [].forEach.call(document.querySelectorAll(".xd-slide"), function (item) {
        offsetTopArr.push(item.offsetTop - 90);
      });

      // 点亮当前的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
        if (
          scrollTop + document.documentElement.clientHeight ===
          document.documentElement.scrollHeight
        ) {
          navIndex = offsetTopArr.length - 1;
        }
      }
      //滚动条滑到底部
      let scrollHeight = Math.round(
        document.body.scrollHeight || document.documentElement.scrollHeight
      );
      let scrollTops = Math.round(
        document.body.scrollTop || document.documentElement.scrollTop
      );
      let clientHeight = Math.round(
        document.body.clientHeight || document.documentElement.clientHeight
      );
      if (scrollHeight == scrollTops + clientHeight) {
        this.tabIndex = 3;
      } else {
        this.tabIndex = navIndex;
      }
      console.log(scrollHeight, scrollTops + clientHeight);
      this.setSlider();
      this.easyCard();
    },
    setSlider() {
      let slide = document.querySelectorAll(".xd-tab")[this.tabIndex];
      if (this.tabIndex > 0) {
        this.sliderWidth = slide.offsetWidth + "px";
        this.sliderLeft = slide.offsetWidth + slide.offsetLeft + 615 + "px";
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/index.css";
</style>
